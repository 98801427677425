import React from "react";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";

import {
  ErrorBoundary,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
  Facet
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";

// to do, fix cors
// const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
// const connector = new ElasticsearchAPIConnector({
//   host: "https://gimli-eu-west-1.searchly.com",
//   index: "book_index",
//   apiKey: "7d01d99f2dc488ebd70575c9a8b491ac"
// });
const connector = new ElasticsearchAPIConnector({
  host: "http://localhost:9200",
  index: "books",
});
const config = {
  searchQuery: {
    search_fields: {
      chapter_title: {
        weight: 3
      },
      content: {
        snippet: {
          size: 100,
          fallback: true
        }
      },
      book_title: {},
    },
    result_fields: {
      chapter_title: {
        snippet: {}
      },
      content: {
        snippet: {}
      },
      content_type: {
        snippet: {}
      }
    },
    disjunctiveFacets: ["content_type.keyword"],
    facets: {
      "content_type.keyword": { type: "value" },
    }
  },
  apiConnector: connector,
  alwaysSearchOnInitialLoad: false
};

export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={<SearchBox autocompleteSuggestions={false} />}
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                      <Facet key={"1"} field={"content_type.keyword"} label={"type"} />
                    </div>
                  }
                  bodyContent={
                    <Results
                      titleField={getConfig().titleField}
                      contentField={getConfig().contentField}
                      shouldTrackClickThrough={true}
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
